// export default generatePdf;

import jsPDF, {
  AcroFormTextField
} from 'jspdf';
import mfLogo from '../../Images/logo/metaforce.jpg';
import { f } from 'html2pdf.js';

const generatePdf = (formData, fontStyle = 'helvetica') => {
  // Configuration object for easy adjustments
  const config = {
    margins: {
      left: 40,
      top: 40,
    },
    fontSizes: {
      title: 16,
      sectionHeader: 14,
      label: 10,
      field: 12,
      consent: 10,
    },
    spacing: {
      lineHeight: 15,
      sectionSpacing: 25,
      fieldSpacing: 40,
      topSectionSpacing: 10, // Added space before each section
    },
    logo: {
      width: 100,
      height: 50,
    },
    underlineWidth: 180,
  };

  const doc = new jsPDF('p', 'pt', 'a4');
  const pageWidth = doc.internal.pageSize.width;
  const pageHeight = doc.internal.pageSize.height;


  // Set font style
  doc.setFont(fontStyle);

  let currentPositionY = config.margins.top;

  // Add company logo on the left
  doc.addImage(mfLogo, 'JPG', config.margins.left, currentPositionY, config.logo.width, config.logo.height);

  const title_text = 'Criminal Record Verification\n Informed Consent Form'

  // Add title "Background Check Form" on the same line as the logo
  doc.setFontSize(config.fontSizes.title);
  doc.setFont(fontStyle, 'bold');
  const titleWidth = doc.getStringUnitWidth(title_text) * 15// config.fontSizes.title;
  doc.text(title_text, pageWidth - config.margins.left - titleWidth, currentPositionY + 30);

  // Reset font to normal
  doc.setFont(fontStyle, 'normal');

  currentPositionY += config.logo.height + config.spacing.sectionSpacing;

  const formatDate = (date) => {
    if (date && date._isAMomentObject) {
      return date.format('YYYY-MM-DD');
    }
    return date || '';
  };

  const addField = (label, value, x, y, width) => {

    y = checkAddPage(y)
    const addMultiLineText = (text, fontSize, startX, startY, lineWidth) => {
      doc.setFontSize(fontSize);
      const lines = doc.splitTextToSize(text, lineWidth);
      doc.text(lines, startX, startY);
      return lines.length;
    };

    let fieldHeight = 0;

    // Add label
    const labelLines = addMultiLineText(label, config.fontSizes.label, x, y, width);
    fieldHeight += labelLines * config.spacing.lineHeight;

    // Calculate start Y for value
    const valueY = y + fieldHeight;

    // Add value
    const displayValue = value ? value.toString() : '';
    const valueLines = addMultiLineText(displayValue, config.fontSizes.field, x, valueY, width);
    fieldHeight += valueLines * config.spacing.lineHeight;

    // Draw a single underline below the value
    const underlineY = valueY + (valueLines * config.spacing.lineHeight);
    doc.line(x, underlineY, x + width, underlineY);

    // Calculate the total height of this field
    const totalFieldHeight = fieldHeight + config.spacing.fieldSpacing;

    // Return the new Y position
    return y + totalFieldHeight;
  };

  const addFormField = (label, fieldName, x, y, width, isMultiline = false) => {
    // Ensure page break if necessary
    y = checkAddPage(y);
  
    const addMultiLineText = (text, fontSize, startX, startY, lineWidth) => {
      doc.setFontSize(fontSize);
      const lines = doc.splitTextToSize(text, lineWidth);
      doc.text(lines, startX, startY);
      return lines.length;
    };
  
    let fieldHeight = 10;
  
    // Add label above the field
    const labelLines = addMultiLineText(label, config.fontSizes.label, x, y, width);
    fieldHeight += labelLines * config.spacing.lineHeight;
  
    // Calculate the starting Y position for the field
    const fieldY = y + fieldHeight;
  
    // Add AcroFormTextField
    const field = new AcroFormTextField();
    field.fieldName = fieldName;
    field.x = x;
    field.y = fieldY;
    field.width = width;
    field.height = 20; // Use config or default to 10
  
    // Optional: Set multiline for the field
    if (isMultiline) {
      field.multiline = true;
    }
  
    // Add the field to the document
    doc.addField(field);
  
    // Draw a line/underline beneath the field (optional)
    const underlineY = fieldY + field.height + config.spacing.lineHeight;
    doc.line(x, underlineY, x + width, underlineY);
  
    // Calculate the total height of the field with the label, field, and underline
    const totalFieldHeight = fieldHeight + field.height + config.spacing.fieldSpacing;
  
    // Return the new Y position
    return y + totalFieldHeight;
  };

  const addSectionHeader = (header) => {
    currentPositionY += config.spacing.topSectionSpacing;
    doc.setFontSize(config.fontSizes.sectionHeader);
    doc.setFont(fontStyle, 'bold');
    doc.text(header, config.margins.left, currentPositionY);
    doc.setFont(fontStyle, 'normal');
    currentPositionY += config.spacing.sectionSpacing;
  };

  const checkAddPage = (currentPositionY) => {
    if (currentPositionY + config.spacing.lineHeight > pageHeight - config.margins.top) {
      doc.addPage();
      currentPositionY = config.margins.top;
    }
    return currentPositionY
  }

  const addTextWithAutoPageBreak = (text, fontSize = config.fontSizes.field, defaultStyle='normal') => {
    doc.setFontSize(fontSize);
    doc.setFont(fontStyle, defaultStyle);

    const lineWidth = pageWidth - 2 * config.margins.left;
    const textLines = doc.splitTextToSize(text, lineWidth);
    
    textLines.forEach(line => {
      if (currentPositionY + config.spacing.lineHeight > pageHeight - config.margins.top) {
        doc.addPage();
        currentPositionY = config.margins.top;
      }
      doc.text(line, config.margins.left, currentPositionY);
      currentPositionY += config.spacing.lineHeight;
    });
  };

  addSectionHeader("Personal Information");

  // First row
  const row1Y = currentPositionY;
  const post1Y = addField("Surname (Last Name):", formData.surname, config.margins.left, row1Y, config.underlineWidth);
  const post2Y = addField("Given Name(s):", formData.givenName, config.margins.left + 300, row1Y, config.underlineWidth);
  currentPositionY = Math.max(post1Y, post2Y);

  // Second row
  const row2Y = currentPositionY;
  const post3Y = addField("Surname (Last Name) at birth:", formData.surnameBirth, config.margins.left, row2Y, config.underlineWidth);
  const post4Y = addField("Former name(s):", formData.formerName, config.margins.left + 300, row2Y, config.underlineWidth);
  currentPositionY = Math.max(post3Y, post4Y);

  // Third row
  const row3Y = currentPositionY;
  const post5Y = addField("Place of birth (City/Province/State/Country):", formData.placeOfBirth, config.margins.left, row3Y, config.underlineWidth);
  const post6Y = addField("Date of Birth (YYYY-MM-DD):", formatDate(formData.dateOfBirth), config.margins.left + 300, row3Y, config.underlineWidth);
  currentPositionY = Math.max(post5Y, post6Y);

  // Fourth row
  doc.setFontSize(config.fontSizes.label);
  doc.text("Sex (Check One):", config.margins.left, currentPositionY);
  doc.rect(config.margins.left, currentPositionY + 5, 10, 10);
  doc.text("Male", config.margins.left + 15, currentPositionY + 13);
  doc.rect(config.margins.left + 60, currentPositionY + 5, 10, 10);
  doc.text("Female", config.margins.left + 75, currentPositionY + 13);
  doc.rect(config.margins.left + 130, currentPositionY + 5, 10, 10);
  doc.text("Other", config.margins.left + 145, currentPositionY + 13);
  if (formData.gender === 'male') {
    doc.text("X", config.margins.left + 2, currentPositionY + 13);
  } else if (formData.gender === 'female') {
    doc.text("X", config.margins.left + 62, currentPositionY + 13);
  } else if (formData.gender === 'other') {
    doc.text("X", config.margins.left + 132, currentPositionY + 13);
  }
  currentPositionY += config.spacing.fieldSpacing;

  // Fifth row
  const row4Y = currentPositionY;
  const post7Y = addField("Phone Number(s):", formData.phone, config.margins.left, row4Y, config.underlineWidth);
  const post8Y = addField("Email Address:", formData.email, config.margins.left + 300, row4Y, config.underlineWidth);
  currentPositionY = Math.max(post7Y, post8Y);

  // Current Address Section
  addSectionHeader("Current Home Address:");

  // Address row 1
  const addressRow1Y = currentPositionY;
  const addr1Y = addField("Number", formData.currentStreetNumber, config.margins.left, addressRow1Y, 50);
  const addr2Y = addField("Street", formData.currentStreetName, config.margins.left + 100, addressRow1Y, 250);
  const addr3Y = addField("Apartment", formData.currentUnitNumber, config.margins.left + 400, addressRow1Y, 100);
  currentPositionY = Math.max(addr1Y, addr2Y, addr3Y);

  // Address row 2
  const addressRow2Y = currentPositionY;
  const addr4Y = addField("City", formData.currentCity, config.margins.left, addressRow2Y, 150);
  const addr5Y = addField("Province", formData.currentProvince, config.margins.left + 200, addressRow2Y, 150);
  const addr6Y = addField("Postal Code", formData.currentPostalCode, config.margins.left + 400, addressRow2Y, 100);
  currentPositionY = Math.max(addr4Y, addr5Y, addr6Y);

  // Additional fields from the original version
  currentPositionY = addField("Country", formData.currentCountry, config.margins.left, currentPositionY, 100);


  let index = 0;
  while (formData[`previousStreetNumber${index}`]) {

    addSectionHeader(`Previous Address ${index + 1}`);
    let addressPosTmp = currentPositionY
    
    let p1 = addField("Number", formData[`previousStreetNumber${index}`], config.margins.left, addressPosTmp, 50);
    let p2 = addField("Street", formData[`previousStreetName${index}`], config.margins.left + 100, addressPosTmp, 250);
    let p3 = addField("Apartment", formData[`previousUnitNumber${index}`], config.margins.left + 400, addressPosTmp, 100);
    
    addressPosTmp = Math.max(p1, p2, p3);
    addressPosTmp = checkAddPage(addressPosTmp)
    currentPositionY = addressPosTmp
    p1 = addField("City", formData[`previousCity${index}`], config.margins.left, addressPosTmp, 150);
    p2 = addField("Province", formData[`previousProvince${index}`], config.margins.left + 200, addressPosTmp, 150);
    p3 = addField("Postal Code", formData[`previousPostalCode${index}`], config.margins.left + 400, addressPosTmp, 100);
    addressPosTmp = Math.max(p1, p2, p3);
    addressPosTmp = checkAddPage(addressPosTmp)

    currentPositionY = addressPosTmp

    // addField("Years", formData[`previousYears${index}`], config.margins.left, currentPositionY, 100);
    // addField("Months", formData[`previousMonths${index}`], config.margins.left + 150, currentPositionY, 100);
    currentPositionY = addField("Country", formData[`previousCountry${index}`], config.margins.left, currentPositionY, 100);
    currentPositionY = checkAddPage(currentPositionY)

    // currentPositionY += config.spacing.sectionSpacing;
    index++;
  }

    currentPositionY += config.spacing.sectionSpacing;
    // Reason for the Criminal Record Verification:
    currentPositionY = checkAddPage(currentPositionY)
    addSectionHeader("Reason for the Criminal Record Verification:");
    // First row
    currentPositionY = addField("Reason for Request (example Employment-Employer-Job Title):", formData.reasonOfRequest, config.margins.left, currentPositionY, config.underlineWidth);
    // currentPositionY += config.spacing.fieldSpacing;

    currentPositionY = addField("Organization Requesting Search:", formData.organizationRequesting, config.margins.left, currentPositionY, config.underlineWidth);
    // currentPositionY += config.spacing.fieldSpacing;

    // Second row
    addField("Contact Name:", formData.ozContactName, config.margins.left, currentPositionY, config.underlineWidth);
    currentPositionY = addField("Contact Phone:", formData.ozContactPhone, config.margins.left + 300, currentPositionY, config.underlineWidth);
    // currentPositionY += config.spacing.fieldSpacing;

  // Consent Section
  addSectionHeader("Consent and Authorization");

  const consentText = 
    `I HEREBY CONSENT TO THE SEARCH OF THE RCMP National Repository of Criminal Records based on the name(s), date of birth, and where used, the declared criminal record history provided by myself. I understand that this verification of the National Repository of Criminal Records is not being confirmed by fingerprint comparison which is the only true means by which to confirm if a criminal record exists in the National Repository of Criminal Records.`
  ;
  addTextWithAutoPageBreak(consentText, config.fontSizes.consent);
  currentPositionY += config.spacing.lineHeight

  const policeSystemConsentText = 
    `POLICE INFORMATION SYSTEM(S) – I HEREBY CONSENT TO THE SEARCH OF police information systems, as a part of a Police Information Check, which will consist of a search of the following systems: CPIC Investigative Data Bank, Police Information Portal (PIP), and Other (if applicable).`
  ;
  addTextWithAutoPageBreak(policeSystemConsentText, config.fontSizes.consent);
  currentPositionY += config.spacing.lineHeight


  const authorizationText = 
    `AUTHORIZATION AND WAIVER to provide confirmation of criminal record or any police information. I certify that the information set out by me in this application is true and correct to the best of my ability. I consent to the release of the results of the criminal record checks to METAFORCE CAREERS INC., located in MISSISSAUGA, CANADA.`
  ;
  addTextWithAutoPageBreak(authorizationText, config.fontSizes.consent);

  const releaseText = 
    `I hereby release and forever discharge all members and employees of the processing Police Service and the Royal Canadian Mounted Police from any and all actions, claims, and demands for damages, loss, or injury howsoever arising which may hereafter be sustained by myself as a result of the disclosure of information by the COBOURG POLICE SERVICE to METAFORCE CAREERS INC, MISSISSAUGA, CANADA.`
  ;
  addTextWithAutoPageBreak(releaseText, config.fontSizes.consent);
  currentPositionY += config.spacing.lineHeight


  // Signature Section
  addSectionHeader("Signature and Consent");
  currentPositionY = addField("Signature of Applicant", formData.applicantSignature, config.margins.left, currentPositionY, 300);
  // currentPositionY += config.spacing.fieldSpacing;
  currentPositionY = addField("Signed At", formData.applicantSignature, config.margins.left, currentPositionY, 300);
  // currentPositionY += config.spacing.fieldSpacing;
  currentPositionY = addField("Date Signed", formatDate(formData.dateSigned), config.margins.left, currentPositionY, 200);

  addSectionHeader("Identification Verification");
  // addressPosTmp = Math.max(p1, p2, p3);
  let addressPosTmp = checkAddPage(currentPositionY)
  currentPositionY = addressPosTmp

  // First row
  const idY = currentPositionY;
  const idrow1Y = addFormField("Witnessing Agent's Name:", 'fdewf' , config.margins.left, currentPositionY, config.underlineWidth);
  const idrow2Y = addFormField("Identification Verified:", 'ewfcwe' , config.margins.left + 300, currentPositionY, config.underlineWidth);


  currentPositionY = Math.max(idrow1Y, idrow2Y);
  addressPosTmp = checkAddPage(currentPositionY)
  currentPositionY = addressPosTmp
  
  // Second row
  const id2Y = currentPositionY;
  const idrow3Y = addFormField("Witnessing Agent' Sign:", 'efwe', config.margins.left, id2Y, config.underlineWidth);
  const idrow4Y = addFormField("Type of Photo ID Viewed:", 'feefw', config.margins.left + 300, id2Y, config.underlineWidth);
  
  currentPositionY = Math.max(idrow3Y, idrow4Y);
  addressPosTmp = checkAddPage(currentPositionY)
  currentPositionY = addressPosTmp
  const ending= "Information related to this criminal record check is collected, retained and disclosed in accordance with applicable privacy legislation.\nMetaforce Careers Inc. Mississauga, Canada"
  addTextWithAutoPageBreak(ending, config.fontSizes.consent, 'bold');


  // Save PDF

  // Saving the PDF
  doc.save('Criminal-Record-Verification-Form.pdf');
};

export default generatePdf;
